

//  General page
//---------------------------------
.preferences-page__inside-content--general {

  .bp4-form-group {
    max-width: 400px;
    margin-bottom: 24px;

    .bp4-label {
      min-width: 190px;
      font-weight: 400;
      color: #8C8C8C;
    }

    .bp4-form-content {
      width: 100%;
    }
  }
}
