@import '../../_base.scss';

// Dialog
.#{$ns}-dialog{
  background: #fff;
  width: 768px;
  border-radius: 10px;

  &-header{
    min-height: 57px;
    background: #fff;
    border-radius: 10px 10px 0 0;

    .bp4-heading{
      font-size: 18px;
      font-weight: 500;
    }

    svg{
      width: 20px;
      height: 20px;
      color: #262626;
    }
  }

  &-body{
    &.is-loading{
      .bp4-spinner{
        padding-top: 10px;
        margin-bottom: -10px;
      } 
    }
  }
}
