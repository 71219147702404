.bp4-alert {
  max-width: 444px;

  .bp4-alert-body {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    text-align: center;

    .bp4-icon {
      padding: 14px;
      border-radius: 32px;
      background: #fff1f0;
      color: #ff4d4f;
    }
  }

  .bp4-alert-footer {
    display: flex;
    justify-content: center;

    .bp4-intent-danger {
      background-color: #FF4D4F;
      color: #fff;
    }

    .bp4-intent-warning {
      background-color: #FF4D4F;
      color: #fff;

      &:hover,
      &:focus {
        background-color: #FF4D4F !important;
        color: #fff !important;
      }
    }

    .bp4-button:not([class*='bp4-intent-']) {
      background-color: #fff;
      color: #262626;
      border-radius: 4px;
      border: 1px solid #D9D9D9;
    }

    .bp4-button {
      min-width: 70px;
    }
  }

  h3 {
    margin-bottom: 20px;
  }

  p{
    color: #616161
  }
}