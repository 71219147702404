@import 'src/style/_base.scss';

.sidebar {
  background: $sidebar-background;
  width: $sidebar-width;
  color: $sidebar-text-color;
  height: 100%;
  z-index: $sidebar-zindex;
  position: relative;

  .ScrollbarsCustom-Track {

    &.ScrollbarsCustom-TrackY,
    &.ScrollbarsCustom-TrackX {
      background: rgba(0, 0, 0, 0);
    }
  }

  .ScrollbarsCustom-Thumb {

    &.ScrollbarsCustom-ThumbX,
    &.ScrollbarsCustom-ThumbY {
      background: rgba(0, 0, 0, 0);
    }
  }

  .ScrollbarsCustom-Content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &:hover {
    .ScrollbarsCustom-Thumb {

      &.ScrollbarsCustom-ThumbX,
      &.ScrollbarsCustom-ThumbY {
        background: rgba(255, 255, 255, 0.25);
      }
    }
  }

  &__menu-expanded{
    margin-top: 30px;

    .sidebar-menu{
      .bp4-menu-item:hover{
        margin: 0px;
      }
      .bp4-submenu{
        .bp4-menu-item:hover,
        .bp4-active{
          margin: 0px;
        }
      }
    }
  }

  &__head {
    display: flex;
    flex-direction: column;
    padding: 15px 10px;
    background-color: #005572;
    box-shadow: 0px 3px 4px 2px #004B65;

    &-section-1{
      display: flex;
    }

    &-separator{
      margin-top: 12px;
    }

    &-logo {
      position: absolute;
      top: 16px;
      left: 12px;
      opacity: 0;
      visibility: hidden;

      svg {
        opacity: $sidebar-logo-opacity;
      }
    }

    &-organization {
      .title {
        background: transparent;
        padding: 0;
        min-height: auto;
        min-width: auto;
        max-width: 100%;
        font-weight: 600;
        outline: 0;
        font-size: 15px;

        &:hover,
        &:focus,
        &:active,
        &.bp4-active {
          background: transparent;
        }

        .bp4-button-text {
          margin-right: 4px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          display: block;
          color: #FFFFFF99;
        }

        svg {
          fill: rgba(255, 255, 255, 0.3);
        }
      }

      .bp4-popover-wrapper,
      .bp4-popover-target {
        max-width: 75%;
        display: inline-block;
      }

      .subtitle {
        display: block;
        font-size: 12px;
        margin-top: 4px;
        color: #FFFFFF;
      }
    }

    .sidebar__head-logo {
      transition: transform 0.05s ease-in-out;
    }

    .is-subscription-inactive:not(.sidebar--mini-sidebar) & {
      opacity: 0.6;
    }
  }

  &__menu{
    background-color: #124C60;
  }

  &__scroll-wrapper {
    height: 100%;
  }

  &__version {
    margin-top: auto;
    padding: 0 20px 20px;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.6);
  }

  &__inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #124C60;
  }

  &-menu {
    background: transparent;
    padding: 5px 0 0 0;
    min-width: $sidebar-width;
    border-radius: 0;

    .#{$ns}-menu-item {
      color: $sidebar-menu-item-color;
      border-radius: 0;
      padding: 8px 16px;
      font-size: 16px;
      font-weight: 400;
      font-family: "Public Sans", sans-serif;

      &:hover,
      &:focus,
      &.bp4-active {
        margin: 0 10px;
        border-radius: 8px;
        background: $sidebar-submenu-item-bg-color;
        color: $sidebar-menu-item-color-active;
      }

      &:focus,
      &:active {
        background: #01143e;
      }

      >.#{$ns}-icon {
        color: #FFFFFF;
        margin-right: 16px;
        margin-top: 0;
      }

      >.#{$ns}-icon-caret-right {
        margin-right: -4px;
        margin-top: 3px;
        color: rgba(255, 255, 255, 0.25);
      }

      &-labeler {
        display: block;
        color: $sidebar-menu-label-color;
        background-color: #005572;
        font-size: 11px;
        padding: 8px 20px;
        margin: 5px 10px;
        border-radius: 7px;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 1px;
        font-family: "Public Sans", sans-serif;

        html[lang^="ar"] & {
          font-size: 12px;
          letter-spacing: 0;
        }
      }

      &-separator{
        display: block;
        // color: $sidebar-menu-label-color;
        font-size: 10px;
        padding: 0px 5px;
        margin-top: 4px;
        font-weight: 500;

        html[lang^="ar"] & {
          font-size: 12px;
          letter-spacing: 0;
        }
      }

      &:hover .bp4-button.menu-item__add-btn {
        display: inline-block;
      }

      .sidebar-icon{
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }

    .#{$ns}-submenu {
      .#{$ns}-collapse {
        &-body {
          background-color: rgba(255, 255, 255, 0.03);
          padding-bottom: 6px;
          padding-top: 6px;
        }

        .#{$ns}-menu-item {
          padding: 8px 20px;
          font-size: 15px;
          color: $sidebar-submenu-item-color;

          &:hover,
          &.bp4-active {
            background: transparent;
            color: $sidebar-submenu-item-hover-color;
          }

          &.bp4-active {
            font-weight: 500;
          }
        }
      }

      .#{$ns}-popover {
        padding: 0;

        &-content {
          box-shadow: 0 0 0;
        }
      }
    }

    .#{$ns}-popover-target.#{$ns}-popover-open .#{$ns}-menu-item {
      color: $sidebar-menu-item-color;
    }

    .#{$ns}-menu-divider {
      border-top-color: rgba(255, 255, 255, 0.1);
      color: #6b708c;
      margin: 4px 0;
    }

    .#{$ns}-menu-spacer {
      margin: 4px 0;
      height: 1px;
    }
  }

  &--mini-sidebar {
    position: fixed;
    white-space: nowrap;
    width: 50px;

    .sidebar__head {
      .sidebar__head-logo {
        transition: opacity 0.3s ease-in-out;
        transition-delay: 0.15s;
        opacity: 1;
        visibility: visible;
      }

      // Hide text of bigcapital logo.
      &-logo {}

      &-organization {
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
      }
    }

    .sidebar__menu,
    .sidebar__version {
      transition: opacity 0.3s ease-in-out;
      opacity: 0;
    }

    .sidebar__scroll-wrapper {
      background: $sidebar-background;
      transition: min-width 0.15s ease-in-out;
      min-width: 50px;

      .ScrollbarsCustom-Scroller {
        overflow: hidden !important;
      }

      &:hover {
        min-width: 190px;

        .sidebar__head-logo {
          opacity: 0;
          transition-delay: 0s;
        }

        .sidebar__head-organization,
        .sidebar__menu,
        .sidebar__version {
          opacity: 1;
        }

        .ScrollbarsCustom-Scroller {
          overflow: scroll !important;
        }
      }
    }
  }

  .bp4-button.menu-item__add-btn {
    width: auto;
    padding: 2px;
    margin-right: 0px;
    position: relative;
    top: 1px;
    border-radius: 3px;
    display: none;
    vertical-align: top;

    &:not([class*='bp4-intent-']):not(.bp4-minimal):not(:disabled) {
      .bp4-icon {
        color: rgba(255, 255, 255, 0.4);
      }

      &,
      &:hover {
        min-height: auto;
        min-width: auto;
        outline: 0;
        background-color: transparent;
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.12);

        .bp4-icon {
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }

    .bp4-icon {
      margin: 0;
      display: block;
    }
  }
}

.menu--dashboard-organization {
  padding: 10px;

  .org-item {
    display: flex;
    align-items: center;

    &__logo {
      height: 40px;
      width: 40px;
      line-height: 40px;
      border-radius: 3px;
      background-color: #CB22E5;
      text-align: center;
      font-weight: 400;
      font-size: 16px;
      color: #fff;
    }

    &__name {
      margin-left: 12px;
      font-weight: 600;
    }

    &__divider {
      margin: 4px 0;
      height: 1px;
      background: #ebebeb;
    }
  }
}