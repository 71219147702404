

.root {
  padding: 20px;
  border-radius: 6px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  background: #fff;
  position: relative;
}