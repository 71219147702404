@import '../../_base.scss';

// Preferences sidebar.
// -----------------------------
.preferences-sidebar {
  .ScrollbarsCustom-Scroller{
    background-color: #c5e7f3;
  }

  background: #eaeef6;
  border-right: 1px solid #c6d0d9;
  min-width: 220px;
  max-width: 220px;
  height: 100%;
  position: fixed;

  &__wrapper {
    height: 100%;
  }

  .ScrollbarsCustom-Track {

    &.ScrollbarsCustom-TrackY,
    &.ScrollbarsCustom-TrackX {
      background: rgba(0, 0, 0, 0);
    }
  }

  .ScrollbarsCustom-Thumb {

    &.ScrollbarsCustom-ThumbX,
    &.ScrollbarsCustom-ThumbY {
      background: rgba(0, 0, 0, 0);
    }
  }

  &:hover {
    .ScrollbarsCustom-Thumb {

      &.ScrollbarsCustom-ThumbX,
      &.ScrollbarsCustom-ThumbY {
        background: rgba(0, 0, 0, 0.15);
      }
    }
  }

  &__head {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 60px;
    padding: 0 22px;
    background-color: #005572;

    h2 {
      font-size: 24px;
      font-family: "Public Sans", sans-serif;
      color: #FFFFFF;
      font-weight: 400;
      margin: 0;
    }
  }

  &__menu {
    padding: 0;
    background: transparent;

    .#{$ns}-menu-item {
      padding: 10px 22px;
      font-size: 16px;
      font-family: "Public Sans", sans-serif;
      font-weight: 400;
      color: #333;

      &:hover,
      &.#{$ns}-active {
        margin: 0 10px;
        border-radius: 8px;
        background-color: #f3f3f3;;
        color: #00102b;
      }
    }
  }
}