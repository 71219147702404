
.content {
}

.hintText{
  display: flex;
  font-size: 11px;
  margin-top: 6px;
  color: #738091;
  justify-content: space-between;
}

.attachments{
  margin-top: 12px;
}

.attachmentItem{
  border-top: 1px solid #D3D8DE;
  border-left: 1px solid #D3D8DE;
  border-right: 1px solid #D3D8DE;
  padding: 10px 14px;
  justify-content: space-between;

  &:first-child {
    border-radius: 3px 3px 0 0;
  }
  &:last-child{
    border-radius: 0 0 3px 3px;
    border-bottom: 1px solid #D3D8DE;
  }
}

.attachmentFilenameText{
  
}

.attachmentSizeText,
.attachmentLoadingText{
  font-size: 13px;
  color: #738091;
}

.attachmentContent{
}

.attachmentIcon{
  color: #626b7c;
}

.label{
  font-size: 12px;
  margin-bottom: 4px;
}

.dropzoneRoot{
  min-height: 140px;
  padding: 10px;
}

.attachmentIconWrap{
  width: 20PX;
  text-align: right;
}