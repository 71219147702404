
.root {
 
}

.title{
  color: #262626;
  font-weight: 600;
  font-size: 18px;
}

.iconWrap{
  color: #8F99A8;
}
.subtitle {
  color: #8F99A8;
}

.dropzoneContent{
  display: flex;
  gap: 40px;
  text-align: center;
  margin: auto;  
}

.buttons-wrap {
  margin-top: 12px;
}

.button{
  border-radius: 4px;
  color: #FFFFFF !important; 
  background-color: #1890FF !important;
}

.dropzoneHint{
  display: flex;
  font-size: 12px;
  color: #8F99A8;
  justify-content: space-between;
}