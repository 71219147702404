[data-icon='bigcapital'] {
  path {
    fill: #004dd0;
  }
  .path-1,
  .path-13 {
    fill: #2d95fd;
  }
}

.bigcapital--alt {
  svg {
    path,
    .path-13,
    .path-1 {
      fill: #fff;
    }
  }
}
