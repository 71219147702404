

// Noto Sans
// -------------------------------------
@font-face {
  font-family: Poppins;
  src: local('Poppins'), url('../fonts/poppins/PoppinsSemibold-8l8n.woff') format('woff');
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: local('Poppins'), url('../fonts/poppins/PoppinsRegular-B2Bw.woff') format('woff');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: local('Poppins'), url('../fonts/poppins/poppinsmedium-1jpv-webfont.woff') format('woff');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: local('Poppins'), url('../fonts/poppins/poppinsbold-gdja-webfont.woff') format('woff');
  font-style: normal;
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: local('Poppins'), url('../fonts/poppins/poppinsblack-vxoe-webfont.woff') format('woff');
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}

// Segoe UI Arabic
// -------------------------------------
//  Segoe UI Arabic - Regular
@font-face {
  font-family: 'Segoe UI';
  src: local('Noto Sans'),
    url('../fonts/SegoeArabicUI-Regular.woff2') format('woff');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

//  Segoe UI Arabic - Bold
@font-face {
  font-family: 'Segoe UI';
  src: local('Noto Sans'),
    url('../fonts/SegoeArabicUI-Bold.woff2') format('woff');
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}

// Segoe UI Arabic - Semi Bold
@font-face {
  font-family: 'Segoe UI';
  src: local('Noto Sans'),
    url('../fonts/SegoeArabicUI-SemiBold.woff2') format('woff');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}
