.scrollbar-custom-track {
  width: 100%;
  overflow: auto;
  display: block;
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 6px inset;
    background: rgb(191, 191, 191);
    border-radius: 100vw;
    margin: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 100vw;
    background-color: #19376d;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #19376d;
  }
}
