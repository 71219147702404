

.cloud-spinner{

  &.is-loading:before{
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.8);
    z-index: 999;
  }

  .bp4-spinner{
    position: absolute;
    z-index: 999999;
    left: 50%;
    top: 50%;
    margin-top: -20px;
    margin-left: -20px;
  }
  &:not(.is-loading) .bp4-spinner{
    display: none;
  }
}
